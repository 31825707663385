import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import {
  Button,
  Text,
  TickS,
} from "@totalenergiescode/mobility-business-rev-design-system";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AccountsMsInviteSupportUserRequestProfile } from "@/api/graphql/types/graphql.ts";
import { RoundedTopLeftContainer } from "@/components/containers/RoundedTopLeftContainer";
import { ControlledSelect } from "@/components/shared/ControlledSelect";
import { ControlledTextField } from "@/components/shared/ControlledTextField";
import { PageHeader } from "@/components/shared/PageHeader";
import { useAddSnackbar } from "@/contexts/snackbar";
import { useInviteSupportUser } from "@/hooks/agent/invite/hooks.tsx";
import { inviteSupportUser } from "@/hooks/agent/invite/types.ts";

import {
  StyledButtonContainer,
  StyledContainer,
  StyledDivider,
  StyledForm,
  StyledInputContainer,
  StyledLightGreyText,
  StyledSection,
  StyledSelectContainer,
  StyledTopContainer,
} from "../styles";
import { userFormSchema, type UserFormType } from "../types";

const AddUserForm = () => {
  const { t: tUsers } = useTranslation();
  const addSnackbar = useAddSnackbar();
  const { mutateAsync } = useInviteSupportUser();
  const navigate = useNavigate({ from: "/backoffice-users/add" });
  const { control, getValues } = useForm<UserFormType>({
    resolver: zodResolver(userFormSchema),
  });
  const tempOptions = [
    {
      label: tUsers("users.forms.agent"),
      value: "AGENT",
    },
    {
      label: tUsers("users.forms.superAgent"),
      value: "SUPER_AGENT",
    },
  ];
  const breadcrumbItems = [
    {
      title: tUsers("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: tUsers("users.forms.backOfficeAgents"),
      path: undefined,
    },
    {
      title: tUsers("users.forms.inviteAgent"),
      path: undefined,
    },
  ];
  const handleSubmitMutation = async (e: React.FormEvent) => {
    e.preventDefault();
    const supportUserMapped: inviteSupportUser = {
      email: getValues().email,
      firstName: getValues().name,
      igg: getValues().iGG,
      profile:
        getValues().profile === "AGENT"
          ? AccountsMsInviteSupportUserRequestProfile.Agent
          : AccountsMsInviteSupportUserRequestProfile.SuperAgent,
      jobTitle: getValues().function || "",
      lastName: getValues().familyName,
    };

    mutateAsync(supportUserMapped)
      .then(() => {
        addSnackbar({
          type: "success",
          title: tUsers("users.forms.invitationSent"),
          message: `${tUsers("users.forms.invitationSentDescription")}: ${getValues().email}`,
          isAutoClose: true,
        });
        navigate({
          to: "/backoffice-users",
          params: { isSuccess: true, email: getValues().email },
        });
      })
      .catch(() => {
        addSnackbar({
          type: "error",
          title: tUsers("users.forms.requiredFields"),
          message: ``,
          isAutoClose: true,
        });
      });
  };

  return (
    <>
      <PageHeader
        breadcrumbItems={breadcrumbItems}
        title={tUsers("users.forms.inviteAgent")}
        href={""}
      />
      <RoundedTopLeftContainer>
        <StyledLightGreyText
          variant='sub3'
          fontStyle='italic'
          fontWeight='light'
          sx={{ marginBottom: "20px" }}
        >
          {tUsers("users.forms.requiredFields")}
        </StyledLightGreyText>

        <StyledForm onSubmit={handleSubmitMutation}>
          <StyledTopContainer>
            <StyledSection>
              <Text variant='title3' fontWeight='medium'>
                {tUsers("users.forms.addUser")}
              </Text>
              <StyledDivider />
              <StyledContainer>
                <Text variant='title2' fontWeight='medium'>
                  {tUsers("users.forms.invitationByEmailAddress")}
                </Text>
                <StyledLightGreyText
                  variant='sub3'
                  fontStyle='italic'
                  fontWeight='light'
                >
                  {tUsers("users.forms.emailDiscription")}
                </StyledLightGreyText>
                <StyledInputContainer fixedWidth>
                  <ControlledTextField
                    name='email'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.email"),
                      dataTestId: "users__invite-user-form_first-name-input",
                    }}
                  />
                  <StyledLightGreyText
                    variant='sub3'
                    fontStyle='italic'
                    fontWeight='light'
                    sx={{ marginLeft: "20px", paddingTop: "5px" }}
                  >
                    {tUsers("users.forms.emailDiscription2")}
                  </StyledLightGreyText>
                </StyledInputContainer>
              </StyledContainer>
            </StyledSection>
            <StyledSection>
              <Text variant='title3' fontWeight='medium'>
                {tUsers("users.forms.agent")}
              </Text>
              <StyledDivider />
              <StyledContainer>
                <StyledInputContainer fixedWidth>
                  <ControlledTextField
                    name='name'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.name"),
                      dataTestId: "users__invite-user-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>
                <StyledInputContainer fixedWidth>
                  <ControlledTextField
                    name='familyName'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.familyName"),
                      dataTestId: "users__invite-user-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>
                <StyledInputContainer fixedWidth>
                  <ControlledTextField
                    name='iGG'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: "IGG",
                      dataTestId: "users__invite-user-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>
                <StyledInputContainer fixedWidth>
                  <ControlledTextField
                    name='function'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.functionOptional"),
                      dataTestId: "users__invite-user-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>
              </StyledContainer>
            </StyledSection>
            <StyledSection>
              <Text variant='title3' fontWeight='medium'>
                {tUsers("users.forms.agentProfile")}
              </Text>
              <StyledDivider />

              <StyledContainer>
                <Text variant='title2' fontWeight='medium'>
                  {tUsers("users.forms.invitationByEmailAddress")}
                </Text>
                <StyledLightGreyText
                  variant='sub3'
                  fontStyle='italic'
                  fontWeight='light'
                >
                  {tUsers("users.forms.emailDiscription")}
                </StyledLightGreyText>
              </StyledContainer>
              <StyledSelectContainer>
                <StyledInputContainer fixedWidth>
                  <ControlledSelect
                    control={control}
                    name='profile'
                    namespace='users'
                    selectProps={{
                      className: "test-select",
                      type: "options",
                      options: tempOptions,
                      label: tUsers("users.forms.chooseProfile"),
                      dataTestId:
                        "users__invite-user-form_phone-country-code-select",
                    }}
                  />
                </StyledInputContainer>
              </StyledSelectContainer>
            </StyledSection>
            <StyledButtonContainer>
              <Button
                variant='secondary'
                isLowercase
                buttonText={tUsers("users.forms.cancel")}
                onClick={() => {}}
              />
              <Button
                leftIcon={<TickS width={25} height={25} color='white' />}
                variant='primary'
                type='submit'
                isLowercase
                buttonText={tUsers("users.forms.inviteNewAgent")}
                onClick={() => {}}
              />
            </StyledButtonContainer>
          </StyledTopContainer>
        </StyledForm>
      </RoundedTopLeftContainer>
    </>
  );
};

export default AddUserForm;
