import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEN from "@/assets/locales/en-US/common.json";
import companiesEN from "@/assets/locales/en-US/companies.json";
import homePageEN from "@/assets/locales/en-US/homePage.json";
import profilesEN from "@/assets/locales/en-US/profiles.json";
import searchEN from "@/assets/locales/en-US/search.json";
import signInEN from "@/assets/locales/en-US/signIn.json";
import usersEN from "@/assets/locales/en-US/users.json";
import commonFR from "@/assets/locales/fr-FR/common.json";
import companiesFR from "@/assets/locales/fr-FR/companies.json";
import homePageFR from "@/assets/locales/fr-FR/homePage.json";
import profilesFR from "@/assets/locales/fr-FR/profiles.json";
import searchFR from "@/assets/locales/fr-FR/search.json";
import signInFR from "@/assets/locales/fr-FR/signIn.json";
import usersFR from "@/assets/locales/fr-FR/users.json";

export const defaultNS = "translation";
export const resources = {
  en: {
    translation: {
      ...signInEN,
      ...commonEN,
      ...homePageEN,
      ...searchEN,
      ...companiesEN,
      ...usersEN,
      ...profilesEN,
    },
  },
  fr: {
    translation: {
      ...signInFR,
      ...commonFR,
      ...homePageFR,
      ...searchFR,
      ...companiesFR,
      ...usersFR,
      ...profilesFR,
    },
  },
} as const;

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("lng") || "en-US",
  resources,
  ns: [defaultNS],
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});
export default i18n;
