import { z } from "zod";

//TODO: Add translations
export const userFormSchema = z.object({
  name: z
    .string()
    .trim()
    .regex(/^[A-Za-z]+$/, "users.inviteUser.form.name.errors.charactersOnly")
    .min(1, "users.inviteUser.form.name.errors.required")
    .max(50, "users.inviteUser.form.name.errors.maxLength"),
  familyName: z
    .string()
    .trim()
    .regex(
      /^[A-Za-z]+$/,
      "users.inviteUser.form.familyName.errors.charactersOnly",
    )
    .min(1, "users.inviteUser.form.familyName.errors.required")
    .max(50, "users.inviteUser.form.familyName.errors.maxLength"),
  iGG: z
    .string()
    .trim()
    .regex(/^[A-Za-z0-9]+$/, "users.inviteUser.form.iGG.errors.alphanumeric"),
  email: z.string().trim().email("users.inviteUser.form.email.errors.required"),
  function: z.string().trim().optional().nullable(),
  profile: z.enum(["AGENT", "SUPER_AGENT"]),
});

export type UserFormType = z.infer<typeof userFormSchema>;
