import { Select } from "@totalenergiescode/mobility-business-rev-design-system";
import { Controller, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledSelectProps } from "@/components/shared/ControlledSelect/types";
import { SelectChangeEvent } from "@/types/temporary";

export const ControlledSelect = <T extends FieldValues>({
  name,
  control,
  namespace,
  selectProps,
}: ControlledSelectProps<T>) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const isError =
          typeof selectProps.isError !== "undefined"
            ? selectProps.isError
            : !!fieldState.error;
        const errorMessage = selectProps.errorMessage
          ? selectProps.errorMessage
          : fieldState.error?.message && namespace
            ? t(fieldState.error.message, { ns: namespace })
            : undefined;

        return (
          <Select
            {...selectProps}
            value={field.value}
            onChange={(event: SelectChangeEvent) => {
              field.onChange(event);
              // @ts-expect-error TODO: fix Select typing (problem with 'string | number' value)
              selectProps.onChange?.(stringEvent);
            }}
            isError={isError}
            errorMessage={errorMessage}
          />
        );
      }}
    />
  );
};
