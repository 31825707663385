import { capitalize } from "@mui/material";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  BubbleText,
  Search,
  Subtract,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { GetAccountUsersQuery } from "@/api/graphql/accounts/getAccountUsers/queries";
import { getGraphqlClient } from "@/api/graphql/client";
import { getUserRoleDisplayedName } from "@/components/Accounts/list/helper";
import { EmptyDataInDB } from "@/components/shared/EmptyComponent";
import { Loading } from "@/components/shared/Loading";
import { RolesProfileIcon } from "@/components/shared/RolesProfileIcon";
import { isRolesProfileCode } from "@/components/shared/RolesProfileIcon/types";
import { useFormatDate } from "@/hooks/i18n/useFormatDate";

import {
  StyledButton,
  StyledButtonContainer,
  StyledCell,
  StyledCellHeaderText,
  StyledCellText,
  StyledCellWithEndArrow,
  StyledContainer,
  StyledCurrentCountOnTotal,
  StyledCustomTableContainer,
  StyledDivider,
  StyledRightIcon,
  StyledRoundedLeftContainer,
  StyledRowContainerLink,
  StyledSearchField,
  StyledSearchFieldContainer,
  StyledTable,
  StyledTableHeader,
  StyledTitle,
} from "./styles";

const pageSize = 20;

export const UsersList: React.FC<{ accountId: string }> = ({ accountId }) => {
  const { t: tCompanies } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const { data: accountUsersData, isLoading } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ["users", accountId, searchTerm, currentPage],
    enabled: !!user?.access_token,
    queryFn: async () => {
      if (!user?.access_token) return;

      return getGraphqlClient().request(GetAccountUsersQuery, {
        request: {
          paging: { size: pageSize * currentPage },
          filters: {
            accountId,
            query: {
              user: searchTerm.length > 2 ? searchTerm : "",
            },
          },
        },
      });
    },
  });
  const totalNumberOfUsers =
    accountUsersData?.AccountsMsSearchPrivileges.pagination.total ?? 0;
  const totalNumberOfPages = Math.ceil(totalNumberOfUsers / pageSize);
  const listOfUsers = accountUsersData?.AccountsMsSearchPrivileges.data ?? [];
  const iconProps = {
    width: "20px",
    height: "20px",
  };
  const formatDate = useFormatDate();
  const formattedDate = (date: any) => {
    const millisecondsToDateObject = new Date(Number(date));

    return formatDate(millisecondsToDateObject, "short");
  };

  return (
    <StyledContainer>
      <>
        <StyledRoundedLeftContainer>
          <StyledTitle variant='title2'>
            {tCompanies("companies.list.subtitles.list")}
          </StyledTitle>
          <StyledDivider />
          <StyledSearchFieldContainer>
            <StyledSearchField
              dataTestId='text-field'
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={tCompanies("companies.list.searchPlaceholder")}
              startIcon={<Search width={18} height={18} />}
              value={searchTerm}
            />
          </StyledSearchFieldContainer>
          <StyledCustomTableContainer>
            <StyledTableHeader>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.email")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.name")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.accountProfile")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.status")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.createdAt")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.lastConnection")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {""}
                </StyledCellHeaderText>
              </StyledCell>
            </StyledTableHeader>

            <StyledTable>
              {listOfUsers.length > 0 ? (
                listOfUsers.map((profile) => (
                  <StyledRowContainerLink
                    to={`/users/${profile.UserIdData?.id}`}
                    key={profile.id}
                  >
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {profile?.UserIdData?.email}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {`${profile?.UserIdData?.firstName} ${profile?.UserIdData?.lastName}`}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      {isRolesProfileCode(
                        profile?.Subscriptions?.[0]?.RolesProfileIdData?.code ??
                          "",
                      ) ? (
                        <RolesProfileIcon
                          code={
                            (profile?.Subscriptions?.[0]?.RolesProfileIdData
                              ?.code as
                              | "ADMIN"
                              | "MANAGER"
                              | "ACCOUNTANT"
                              | "VIEWER") ?? ""
                          }
                          size={28}
                        />
                      ) : null}
                      <StyledCellText variant='text2'>
                        {getUserRoleDisplayedName({
                          code: profile?.Subscriptions?.[0]?.RolesProfileIdData
                            ?.code,
                          name: profile?.Subscriptions?.[0]?.RolesProfileIdData
                            ?.name,
                          t: tCompanies,
                        })}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        <BubbleText
                          text={capitalize(
                            tCompanies(
                              `companies.list.status.${profile.status}.label`.toLowerCase(),
                            ),
                          )}
                          variant={
                            profile.status.toLowerCase() === "active"
                              ? "green"
                              : "grey"
                          }
                        />
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {formattedDate(profile?.UserIdData?.createdTs)}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {formattedDate(profile?.enabledLastTs)}
                      </StyledCellText>
                    </StyledCell>

                    <StyledCellWithEndArrow>
                      <Text variant='text2'>
                        <StyledRightIcon />
                      </Text>
                    </StyledCellWithEndArrow>
                  </StyledRowContainerLink>
                ))
              ) : (
                <EmptyDataInDB principalText={tCompanies("common.noData")} />
              )}
            </StyledTable>
          </StyledCustomTableContainer>
          <Loading isLoading={isLoading}>
            {totalNumberOfPages > 1 && currentPage < totalNumberOfPages ? (
              <StyledButtonContainer>
                <StyledButton
                  leftIcon={<Subtract {...iconProps} />}
                  variant='secondary'
                  buttonText={tCompanies("common.pagination.next_page", {
                    pageSize,
                  })}
                  isLowercase={true}
                  onClick={() => setCurrentPage((prev) => prev + 1)}
                ></StyledButton>
              </StyledButtonContainer>
            ) : null}
          </Loading>
          <StyledCurrentCountOnTotal
            currentCount={listOfUsers.length}
            total={totalNumberOfUsers}
            postFix={tCompanies("companies.list.accountsReceivable")}
          />
        </StyledRoundedLeftContainer>
      </>
    </StyledContainer>
  );
};
