import { InfiniteData } from "@tanstack/react-query";

import {
  AccountsMsSearchSupportUsersQuery,
  AccountsMsSearchSupportUsersRequestSortField,
  AccountsMsSearchSupportUsersResponseDataProfile,
  AccountsMsSearchSupportUsersResponseDataStatus,
} from "@/api/graphql/types/graphql.ts";
import { FeSupportUser } from "@/types/agents.ts";

export const fromApiSupportUsersToFeSupportUsers = (
  queryResponse?: InfiniteData<AccountsMsSearchSupportUsersQuery | undefined>,
): FeSupportUser[] => {
  if (!queryResponse || !queryResponse.pages) {
    return [];
  }

  return queryResponse.pages.reduce<Array<FeSupportUser>>(
    (acc, curr) => [
      ...acc,
      ...(curr?.AccountsMsSearchSupportUsers.data || []).map<FeSupportUser>(
        (user): FeSupportUser => ({
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          igg: user.AccessIdData?.igg || "",
          updatedAt: user.AccessIdData?.lastAccessTs || "",
          createdAt: new Date(0),
          accountProfile:
            user.profile ||
            AccountsMsSearchSupportUsersResponseDataProfile.ProfileUnknown,
          status:
            user.status ||
            AccountsMsSearchSupportUsersResponseDataStatus.StatusUnknown,
        }),
      ),
    ],
    [],
  );
};

export const getApiSearchSupportUsersRequestSortField = (
  sortableField: keyof FeSupportUser,
): AccountsMsSearchSupportUsersRequestSortField | null => {
  switch (sortableField) {
    case "id":
      return AccountsMsSearchSupportUsersRequestSortField.Id;
    default:
      return null;
  }
};

export const getApiSearchSupportUsersRequestSortFields = (
  sortableFields: (keyof FeSupportUser)[],
): AccountsMsSearchSupportUsersRequestSortField[] =>
  sortableFields.reduce<AccountsMsSearchSupportUsersRequestSortField[]>(
    (acc, curr) => {
      const mappedKey = getApiSearchSupportUsersRequestSortField(curr);

      if (mappedKey !== null) {
        acc.push(mappedKey);
      }

      return acc;
    },
    [],
  );
