/* eslint-disable */
import * as types from "./graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  query GetAccountUsersQuery($request: AccountsMsSearchPrivilegesRequest) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        enabledLastTs\n        disabledTs\n        invitedTs\n        roles\n        owner\n        status\n        UserIdData {\n          id\n          createdTs\n        }\n        UserIdData {\n          id\n          firstName\n          lastName\n          email\n          phone\n          phoneAlt\n        }\n        Subscriptions {\n          RolesProfileIdData {\n            id\n            name\n            code\n          }\n        }\n      }\n      pagination {\n        total\n      }\n    }\n  }\n":
    types.GetAccountUsersQueryDocument,
  "\n  query GetAccount($request: AccountsMsSearchAccountsRequest!) {\n    AccountsMsSearchAccounts(request: $request) {\n      data {\n        id\n        number\n        name\n        registrationNumber\n        registrationName\n        country\n        city\n        zipCode\n        address\n        createdTs\n        MainPrivilegeIdData {\n          UserIdData {\n            email\n            id\n            firstName\n            lastName\n          }\n          id\n        }\n      }\n    }\n  }\n":
    types.GetAccountDocument,
  "\n  query GetUser($request: AccountsMsSearchUsersRequest) {\n    AccountsMsSearchUsers(request: $request) {\n      data {\n        id\n        email\n        locale\n        timezone\n        firstName\n        lastName\n        phone\n        phoneAlt\n        jobTitle\n      }\n    }\n  }\n":
    types.GetUserDocument,
  "\n  query GetUserAccounts($request: AccountsMsSearchPrivilegesRequest) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        accountId\n        status\n        roles\n        lastUsedTs\n        invitedTs\n        Subscriptions {\n          RolesProfileIdData {\n            id\n            name\n            code\n          }\n        }\n      }\n      pagination {\n        total\n      }\n    }\n  }\n":
    types.GetUserAccountsDocument,
  "\n  query AccountsMsSearchPrivileges(\n    $request: AccountsMsSearchPrivilegesRequest\n  ) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        roles\n        rolesInvalidatedTs\n        disabledTs\n        lastUsedTs\n        invitedTs\n        enabledLastTs\n        userId\n        accountId\n        owner\n        status\n        AccountIdData {\n          id\n          name\n          number\n          registrationName\n          registrationNumber\n          isActive\n          createdTs\n          mainPrivilegeId\n        }\n        Subscriptions {\n          id\n          fromTs\n          tillTs\n          privilegeId\n          rolesProfileId\n          RolesProfileIdData {\n            id\n            name\n            roles\n            code\n            accountId\n            parentId\n            rootId\n            system\n          }\n        }\n      }\n    }\n  }\n":
    types.AccountsMsSearchPrivilegesDocument,
  "\n  mutation InviteAgent($request: AccountsMsInviteSupportUserRequest) {\n    AccountsMsInviteSupportUser(request: $request) {\n      id\n    }\n  }\n":
    types.InviteAgentDocument,
  "\n  query AccountsMsSearchSupportUsers(\n    $request: AccountsMsSearchSupportUsersRequest\n  ) {\n    AccountsMsSearchSupportUsers(request: $request) {\n      data {\n        id\n        accessId\n        rights\n        firstName\n        lastName\n        jobTitle\n        email\n        profile\n        status\n        invitedBy\n        AccessIdData {\n          igg\n          lastAccessTs\n        }\n        status\n      }\n      pagination {\n        page\n        total\n        size\n      }\n    }\n  }\n":
    types.AccountsMsSearchSupportUsersDocument,
  "\n  query AccountsMsCountSupportUsersByStatus {\n    AccountsMsCountSupportUsersByStatus {\n      all\n      enabled\n      disabled\n      inviting\n    }\n  }\n":
    types.AccountsMsCountSupportUsersByStatusDocument,
  "\n  query SearchAccountsMsMe($request: AccountsMsMeRequest) {\n    AccountsMsMe(request: $request) {\n      userId\n      sharedUserProfileTs\n      systemUserInfo\n      supportUserInfo {\n        igg\n        email\n        firstName\n        lastName\n        rights\n      }\n    }\n  }\n":
    types.SearchAccountsMsMeDocument,
  "\n  query GlobalSearch(\n    $searchQuery: String!\n    $limitAccounts: Int!\n    $limitUsers: Int!\n  ) {\n    __typename\n    AccountsMsSearchAccounts(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitAccounts }\n      }\n    ) {\n      data {\n        id\n        number\n        name\n      }\n      pagination {\n        total\n      }\n    }\n    AccountsMsSearchUsers(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitUsers }\n      }\n    ) {\n      data {\n        id\n        email\n        firstName\n        lastName\n      }\n      pagination {\n        total\n      }\n    }\n  }\n":
    types.GlobalSearchDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetAccountUsersQuery($request: AccountsMsSearchPrivilegesRequest) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        enabledLastTs\n        disabledTs\n        invitedTs\n        roles\n        owner\n        status\n        UserIdData {\n          id\n          createdTs\n        }\n        UserIdData {\n          id\n          firstName\n          lastName\n          email\n          phone\n          phoneAlt\n        }\n        Subscriptions {\n          RolesProfileIdData {\n            id\n            name\n            code\n          }\n        }\n      }\n      pagination {\n        total\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetAccountUsersQuery($request: AccountsMsSearchPrivilegesRequest) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        enabledLastTs\n        disabledTs\n        invitedTs\n        roles\n        owner\n        status\n        UserIdData {\n          id\n          createdTs\n        }\n        UserIdData {\n          id\n          firstName\n          lastName\n          email\n          phone\n          phoneAlt\n        }\n        Subscriptions {\n          RolesProfileIdData {\n            id\n            name\n            code\n          }\n        }\n      }\n      pagination {\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetAccount($request: AccountsMsSearchAccountsRequest!) {\n    AccountsMsSearchAccounts(request: $request) {\n      data {\n        id\n        number\n        name\n        registrationNumber\n        registrationName\n        country\n        city\n        zipCode\n        address\n        createdTs\n        MainPrivilegeIdData {\n          UserIdData {\n            email\n            id\n            firstName\n            lastName\n          }\n          id\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetAccount($request: AccountsMsSearchAccountsRequest!) {\n    AccountsMsSearchAccounts(request: $request) {\n      data {\n        id\n        number\n        name\n        registrationNumber\n        registrationName\n        country\n        city\n        zipCode\n        address\n        createdTs\n        MainPrivilegeIdData {\n          UserIdData {\n            email\n            id\n            firstName\n            lastName\n          }\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetUser($request: AccountsMsSearchUsersRequest) {\n    AccountsMsSearchUsers(request: $request) {\n      data {\n        id\n        email\n        locale\n        timezone\n        firstName\n        lastName\n        phone\n        phoneAlt\n        jobTitle\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetUser($request: AccountsMsSearchUsersRequest) {\n    AccountsMsSearchUsers(request: $request) {\n      data {\n        id\n        email\n        locale\n        timezone\n        firstName\n        lastName\n        phone\n        phoneAlt\n        jobTitle\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetUserAccounts($request: AccountsMsSearchPrivilegesRequest) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        accountId\n        status\n        roles\n        lastUsedTs\n        invitedTs\n        Subscriptions {\n          RolesProfileIdData {\n            id\n            name\n            code\n          }\n        }\n      }\n      pagination {\n        total\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetUserAccounts($request: AccountsMsSearchPrivilegesRequest) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        accountId\n        status\n        roles\n        lastUsedTs\n        invitedTs\n        Subscriptions {\n          RolesProfileIdData {\n            id\n            name\n            code\n          }\n        }\n      }\n      pagination {\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query AccountsMsSearchPrivileges(\n    $request: AccountsMsSearchPrivilegesRequest\n  ) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        roles\n        rolesInvalidatedTs\n        disabledTs\n        lastUsedTs\n        invitedTs\n        enabledLastTs\n        userId\n        accountId\n        owner\n        status\n        AccountIdData {\n          id\n          name\n          number\n          registrationName\n          registrationNumber\n          isActive\n          createdTs\n          mainPrivilegeId\n        }\n        Subscriptions {\n          id\n          fromTs\n          tillTs\n          privilegeId\n          rolesProfileId\n          RolesProfileIdData {\n            id\n            name\n            roles\n            code\n            accountId\n            parentId\n            rootId\n            system\n          }\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  query AccountsMsSearchPrivileges(\n    $request: AccountsMsSearchPrivilegesRequest\n  ) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        roles\n        rolesInvalidatedTs\n        disabledTs\n        lastUsedTs\n        invitedTs\n        enabledLastTs\n        userId\n        accountId\n        owner\n        status\n        AccountIdData {\n          id\n          name\n          number\n          registrationName\n          registrationNumber\n          isActive\n          createdTs\n          mainPrivilegeId\n        }\n        Subscriptions {\n          id\n          fromTs\n          tillTs\n          privilegeId\n          rolesProfileId\n          RolesProfileIdData {\n            id\n            name\n            roles\n            code\n            accountId\n            parentId\n            rootId\n            system\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation InviteAgent($request: AccountsMsInviteSupportUserRequest) {\n    AccountsMsInviteSupportUser(request: $request) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation InviteAgent($request: AccountsMsInviteSupportUserRequest) {\n    AccountsMsInviteSupportUser(request: $request) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query AccountsMsSearchSupportUsers(\n    $request: AccountsMsSearchSupportUsersRequest\n  ) {\n    AccountsMsSearchSupportUsers(request: $request) {\n      data {\n        id\n        accessId\n        rights\n        firstName\n        lastName\n        jobTitle\n        email\n        profile\n        status\n        invitedBy\n        AccessIdData {\n          igg\n          lastAccessTs\n        }\n        status\n      }\n      pagination {\n        page\n        total\n        size\n      }\n    }\n  }\n",
): (typeof documents)["\n  query AccountsMsSearchSupportUsers(\n    $request: AccountsMsSearchSupportUsersRequest\n  ) {\n    AccountsMsSearchSupportUsers(request: $request) {\n      data {\n        id\n        accessId\n        rights\n        firstName\n        lastName\n        jobTitle\n        email\n        profile\n        status\n        invitedBy\n        AccessIdData {\n          igg\n          lastAccessTs\n        }\n        status\n      }\n      pagination {\n        page\n        total\n        size\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query AccountsMsCountSupportUsersByStatus {\n    AccountsMsCountSupportUsersByStatus {\n      all\n      enabled\n      disabled\n      inviting\n    }\n  }\n",
): (typeof documents)["\n  query AccountsMsCountSupportUsersByStatus {\n    AccountsMsCountSupportUsersByStatus {\n      all\n      enabled\n      disabled\n      inviting\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query SearchAccountsMsMe($request: AccountsMsMeRequest) {\n    AccountsMsMe(request: $request) {\n      userId\n      sharedUserProfileTs\n      systemUserInfo\n      supportUserInfo {\n        igg\n        email\n        firstName\n        lastName\n        rights\n      }\n    }\n  }\n",
): (typeof documents)["\n  query SearchAccountsMsMe($request: AccountsMsMeRequest) {\n    AccountsMsMe(request: $request) {\n      userId\n      sharedUserProfileTs\n      systemUserInfo\n      supportUserInfo {\n        igg\n        email\n        firstName\n        lastName\n        rights\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GlobalSearch(\n    $searchQuery: String!\n    $limitAccounts: Int!\n    $limitUsers: Int!\n  ) {\n    __typename\n    AccountsMsSearchAccounts(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitAccounts }\n      }\n    ) {\n      data {\n        id\n        number\n        name\n      }\n      pagination {\n        total\n      }\n    }\n    AccountsMsSearchUsers(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitUsers }\n      }\n    ) {\n      data {\n        id\n        email\n        firstName\n        lastName\n      }\n      pagination {\n        total\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GlobalSearch(\n    $searchQuery: String!\n    $limitAccounts: Int!\n    $limitUsers: Int!\n  ) {\n    __typename\n    AccountsMsSearchAccounts(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitAccounts }\n      }\n    ) {\n      data {\n        id\n        number\n        name\n      }\n      pagination {\n        total\n      }\n    }\n    AccountsMsSearchUsers(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitUsers }\n      }\n    ) {\n      data {\n        id\n        email\n        firstName\n        lastName\n      }\n      pagination {\n        total\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
