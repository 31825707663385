enum Rights {
  Agent = "AGENT",
  SuperAgent = "SUPER_AGENT",
}

type SupportUserInfo = {
  email: string;
  firstname: string;
  lastname: string;
  igg: string | undefined;
  rights: Array<string>;
};

export type { SupportUserInfo };
export { Rights };
