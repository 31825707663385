import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Drawer,
  Text,
  TickS,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledSelect } from "@/components/shared/ControlledSelect";
import { ControlledTextField } from "@/components/shared/ControlledTextField";
import { useAddSnackbar } from "@/contexts/snackbar";

import {
  StyledButtonContainer,
  StyledDrawerInnerContainer,
  StyledForm,
  StyledInputContainer,
  StyledRoundedTopLeftContainer,
  StyledSection,
} from "../styles";
import { userFormSchema, type UserFormType } from "../types";

interface EditUserProps {
  isOpen: boolean;
  onClose: () => void;
  userData: UserFormType;
}

const EditUser: FC<EditUserProps> = ({ isOpen, onClose, userData }) => {
  const { t: tUsers } = useTranslation();
  const addSnackbar = useAddSnackbar();
  const { mutate } = useMutation({
    mutationFn: async () => "success",
    onSuccess: () => {
      addSnackbar({
        type: "success",
        title: tUsers("users.forms.modifiedAgent"),
        message: "",
        isAutoClose: true,
      });
      onClose();
    },
  });
  const { control, handleSubmit } = useForm({
    resolver: zodResolver(userFormSchema),
    defaultValues: userData || {},
  });
  const tempOptions = [
    {
      label: tUsers("users.forms.agent"),
      value: "agent",
    },
    {
      label: tUsers("users.forms.superAgent"),
      value: "super-agent",
    },
  ];

  return (
    <>
      <Drawer
        width={"520px"}
        dataTestId={"edit-user-drawer"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Text sx={{ margin: "20px 20px 0 20px" }} variant='title5'>
          {tUsers("users.forms.agentModification")}
        </Text>
        <StyledForm
          onSubmit={handleSubmit(() => {
            mutate();
          })}
        >
          <StyledRoundedTopLeftContainer>
            <StyledDrawerInnerContainer>
              <StyledSection>
                <Text sx={{ marginBottom: "20px" }} variant='title3'>
                  {tUsers("users.forms.personalInformation")}
                </Text>
                <StyledInputContainer>
                  <ControlledTextField
                    name='name'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.name"),
                      dataTestId: "users__invite-user-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>

                <StyledInputContainer>
                  <ControlledTextField
                    name='familyName'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.familyName"),
                      dataTestId: "users__invite-user-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>
                <StyledInputContainer>
                  <ControlledTextField
                    name='iGG'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: "IGG",
                      dataTestId: "users__invite-user-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>
                <StyledInputContainer>
                  <ControlledTextField
                    name='email'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.email"),
                      dataTestId: "users__invite-user-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>
                <StyledInputContainer>
                  <ControlledTextField
                    name='function'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.functionOptional"),
                      dataTestId: "users__invite-user-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>
              </StyledSection>
              <StyledSection>
                <Text sx={{ marginBlock: "35px" }} variant='title3'>
                  {tUsers("users.forms.agentProfile")}
                </Text>
                <ControlledSelect
                  control={control}
                  name='profile'
                  namespace='users'
                  selectProps={{
                    className: "test-select",
                    type: "options",
                    options: tempOptions,
                    label: tUsers("users.forms.chooseProfile"),
                    dataTestId:
                      "users__invite-user-form_phone-country-code-select",
                  }}
                />
              </StyledSection>
              <StyledButtonContainer
                sx={{ marginTop: "40px", justifyContent: "space-between" }}
              >
                <Button
                  variant='secondary'
                  isLowercase
                  buttonText={tUsers("users.forms.cancel")}
                  onClick={onClose}
                />
                <Button
                  leftIcon={<TickS width={25} height={25} color='white' />}
                  variant='primary'
                  isLowercase
                  type='submit'
                  buttonText={tUsers("users.forms.saveChanges")}
                  onClick={() => {}}
                />
              </StyledButtonContainer>
            </StyledDrawerInnerContainer>
          </StyledRoundedTopLeftContainer>
        </StyledForm>
      </Drawer>
    </>
  );
};

export default EditUser;
