import React from "react";

import { Props } from "@/components/shared/CurrentCountOnTotal";
import { StyledText } from "@/components/shared/CurrentCountOnTotal/styles";

const CurrentCountOnTotal: React.FC<Props> = ({
  currentCount,
  total,
  postFix,
  className,
  dataTestId,
}) => {
  if (!total) return null;
  return (
    <div className={className}>
      <StyledText variant='sub3' dataTestId={dataTestId}>
        {currentCount}/{total}
        {postFix ? ` ${postFix}` : ""}
      </StyledText>
    </div>
  );
};
const MemoizedCurrentCountOnTotal = React.memo(CurrentCountOnTotal);

MemoizedCurrentCountOnTotal.displayName = "CurrentCountOnTotal";
export { MemoizedCurrentCountOnTotal as CurrentCountOnTotal };
