import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  InputAdornment,
  Popper,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import {
  Divider,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { FC, HTMLAttributes, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { getGraphqlClient } from "@/api/graphql/client";
import {
  GlobalSearchDocument,
  GlobalSearchQuery,
} from "@/api/graphql/generated/graphql";
import {
  HighlightText,
  SearchExample,
} from "@/components/Search/searchUtils.tsx";

import {
  getTextFieldInputSx,
  StyledAdornmentContainer,
  StyledDiv,
  StyledGroupText,
  StyledIconContainer,
  StyledLeftSideOptionContainer,
  StyledOfficeIcon,
  StyledOptionContainer,
  StyledPersonIcon,
  StyledPopOverContainer,
  StyledSearchField,
  StyledSearchIcon,
} from "./styles";
import { CompanyDataType, EntityType, UserDataType } from "./types";

const SingleOption: FC<{
  params: HTMLAttributes<HTMLLIElement>;
  option: CompanyDataType | UserDataType;
  inputValue: string;
}> = ({ params, option, inputValue }) => (
  <Link
    to={option.type === EntityType.User ? "/users/$id" : "/accounts/$id"}
    params={{ id: option.id }}
  >
    <StyledOptionContainer {...params}>
      <StyledLeftSideOptionContainer>
        {option.type === EntityType.User ? (
          <StyledPersonIcon width={40} height={24} />
        ) : (
          <StyledOfficeIcon width={44} height={28} />
        )}
        <HighlightText
          textToHighlight={inputValue}
          fullText={
            option.type === EntityType.User
              ? option.firstName + " " + option.lastName
              : option.name
          }
        />
      </StyledLeftSideOptionContainer>
      <StyledDiv sx={{ width: "50%" }}>
        <HighlightText
          textToHighlight={inputValue}
          fullText={
            option.type === EntityType.User ? option.email : option.number
          }
        />
      </StyledDiv>
    </StyledOptionContainer>
    <Divider />
  </Link>
);
const Group: FC<{
  params: AutocompleteRenderGroupParams;
  data: GlobalSearchQuery;
}> = ({ params, data }) => {
  const userElements = data.AccountsMsSearchUsers.pagination.total;
  const accountElements = data.AccountsMsSearchAccounts.pagination.total;

  return (
    <StyledDiv>
      <StyledGroupText variant='sub3'>
        {params.group === EntityType.User
          ? userElements > 1
            ? `${userElements} ${params.group}s`
            : `${userElements} ${params.group}`
          : accountElements > 1
            ? `${accountElements} Companies`
            : `${accountElements} ${params.group}`}
      </StyledGroupText>
      {params.children}
    </StyledDiv>
  );
};
const Search = () => {
  const user = useAuth().user;
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const { data, isLoading } = useQuery({
    queryKey: ["search", searchTerm],
    enabled: searchTerm.length > 2,
    queryFn: async () => {
      if (!user?.access_token) return;

      return getGraphqlClient().request(GlobalSearchDocument, {
        searchQuery: searchTerm,
        limitUsers: 5,
        limitAccounts: 5,
      });
    },
  });
  const accounts: CompanyDataType[] =
    data?.AccountsMsSearchAccounts.data?.map((el) => ({
      ...el,
      type: EntityType.Company,
    })) ?? [];
  const users: UserDataType[] =
    data?.AccountsMsSearchUsers.data?.map((el) => ({
      ...el,
      type: EntityType.User,
    })) ?? [];
  const options = [...accounts, ...users];

  return (
    <>
      <Autocomplete
        id='autocomplete'
        options={options}
        loading={isLoading}
        onClose={() => setSearchTerm("")}
        clearOnBlur
        autoHighlight
        groupBy={(option) => option.type}
        popupIcon={null}
        noOptionsText={<SearchExample isNoOptionsVersion />}
        filterOptions={(options) => options}
        getOptionLabel={() => ""}
        PaperComponent={({ children, ...props }) => (
          <StyledPopOverContainer {...props}>
            <StyledDiv sx={{ padding: "15px" }}>
              {!!data && !!options.length && (
                <Text
                  sx={{ marginBottom: "10px", color: "#0077FB" }}
                  variant='title3'
                >
                  {t("search.resultsText1")}{" "}
                  {Number(data.AccountsMsSearchAccounts.pagination.total) +
                    Number(data.AccountsMsSearchUsers.pagination.total)}{" "}
                  {t("search.resultsText2")} "{searchTerm}"
                </Text>
              )}

              {children}
            </StyledDiv>
          </StyledPopOverContainer>
        )}
        PopperComponent={({ children, ...props }) => (
          <Popper sx={{ width: "100%" }} {...props}>
            {searchTerm.length > 2 ? (
              children
            ) : (
              <StyledPopOverContainer>
                <StyledDiv sx={{ padding: "30px" }}>
                  <SearchExample />
                </StyledDiv>
              </StyledPopOverContainer>
            )}
          </Popper>
        )}
        renderOption={(params, option, { inputValue }) => (
          <SingleOption
            key={option.id}
            params={params}
            option={option}
            inputValue={inputValue}
          />
        )}
        renderGroup={(params) =>
          data && <Group key={params.key} params={params} data={data} />
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(props) => (
          <StyledSearchField
            {...props}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            placeholder={t("search.search")}
            data-testid='text-field'
            InputProps={{
              ...props.InputProps,
              sx: getTextFieldInputSx(theme),
              startAdornment: (
                <InputAdornment position='start'>
                  <StyledAdornmentContainer>
                    <StyledIconContainer>
                      <StyledSearchIcon width={18} height={18} />
                    </StyledIconContainer>
                  </StyledAdornmentContainer>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default Search;
