import { Link } from "@tanstack/react-router";
import {
  MainMenuCommiter as Users,
  MainMenuExtend,
  MainMenuReduced,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { useTranslation } from "react-i18next";

import { Home } from "@/components/Icons/generated";
import { Loading } from "@/components/shared/Loading";
import { useMe } from "@/hooks/session/useMe";
import { useUiStore } from "@/stores/ui";

import {
  Drawer,
  StyledBox,
  StyledList,
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
} from "./styles";

const iconSize = 48;

export const SideBar = () => {
  const open = useUiStore(({ uiState }) => uiState.isSidebarOpen);
  const toggleSidebarOpen = useUiStore(
    ({ toggleSidebarOpen }) => toggleSidebarOpen,
  );
  const { t: tSidebar } = useTranslation();
  const { userInfo, isLoading } = useMe();

  return (
    <StyledBox>
      <Loading isLoading={isLoading}>
        <Drawer variant='permanent' open={open}>
          <StyledList>
            <StyledListItem>
              <Link to='/' className='[&.active]:font-bold'>
                <StyledListItemButton open={open}>
                  <StyledListItemIcon open={open}>
                    <Home width={iconSize} height={iconSize} color='white' />
                  </StyledListItemIcon>
                  <StyledListItemText
                    primary={tSidebar("common.sidebar.home")}
                    open={open}
                  />
                </StyledListItemButton>
              </Link>
            </StyledListItem>
            {userInfo.rights.includes("SUPER_AGENT") && (
              <StyledListItem>
                <Link to='/backoffice-users' className='[&.active]:font-bold'>
                  <StyledListItemButton open={open}>
                    <StyledListItemIcon open={open}>
                      <Users width={iconSize} height={iconSize} color='white' />
                    </StyledListItemIcon>
                    <StyledListItemText
                      primary={tSidebar("common.sidebar.users")}
                      open={open}
                    />
                  </StyledListItemButton>
                </Link>
              </StyledListItem>
            )}
            <StyledListItem
              style={{ marginBlockStart: "auto" }}
              onClick={toggleSidebarOpen}
            >
              <StyledListItemButton open={open}>
                <StyledListItemIcon open={open}>
                  {open ? (
                    <MainMenuReduced
                      width={iconSize}
                      height={iconSize}
                      color='white'
                    />
                  ) : (
                    <MainMenuExtend
                      width={iconSize}
                      height={iconSize}
                      color='white'
                    />
                  )}
                </StyledListItemIcon>
              </StyledListItemButton>
            </StyledListItem>
          </StyledList>
        </Drawer>
      </Loading>
    </StyledBox>
  );
};
