import { FeSupportUserStatus } from "@/types/agents.ts";

export const getStatus = (status: FeSupportUserStatus) => {
  switch (status) {
    case "SUPPORT_USER_ENABLED":
      return "green";
    case "SUPPORT_USER_INVITING":
      return "orange";
    case "SUPPORT_USER_DISABLED":
    default:
      return "grey";
  }
};

export const getStatusLabel = (status: FeSupportUserStatus) => {
  switch (status) {
    case "SUPPORT_USER_ENABLED":
      return "active";
    case "SUPPORT_USER_INVITING":
      return "inviting";
    case "SUPPORT_USER_DISABLED":
    default:
      return "inactive";
  }
};
