import { useQuery } from "@tanstack/react-query";
import { Divider } from "@totalenergiescode/mobility-business-rev-design-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { GetUserQuery } from "@/api/graphql/accounts/getUser/queries";
import { getGraphqlClient } from "@/api/graphql/client";
import { AccountsMsSearchUsersResponseData } from "@/api/graphql/generated/graphql.ts";
import { AccountsList } from "@/components/Accounts/list/component";
import { BreadcrumbItem } from "@/components/shared/Breadcrumb/types.ts";
import { PageHeader } from "@/components/shared/PageHeader";

import {
  StyledCard,
  StyledCardContainer,
  StyledCardContent,
  StyledCardTitle,
  StyledContainer,
  StyledIdentity,
  StyledRoundedTopRightCard,
  StyledSectionContainer,
  StyledTitle,
} from "./styles";

const getUserName = (
  profile: Pick<AccountsMsSearchUsersResponseData, "firstName" | "lastName">,
) => profile?.firstName + " " + profile.lastName || "N F";

export const UserDetail: React.FC<{ id: string }> = ({ id }) => {
  const { t: tUsers } = useTranslation();
  const user = useAuth().user;
  const { data, isLoading } = useQuery({
    queryKey: ["user", id],
    enabled: !!user?.access_token,
    queryFn: async () => {
      if (!user?.access_token) return;

      return getGraphqlClient().request(GetUserQuery, {
        request: { filters: { ids: [id] } },
      });
    },
  });

  if (isLoading) return <div>Loading...</div>;

  const userProfile = data?.AccountsMsSearchUsers.data?.[0];
  const breadcrumbItems: BreadcrumbItem[] = [
    {
      title: tUsers("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: userProfile?.firstName + " " + userProfile?.lastName,
      path: undefined,
    },
  ];

  if (!userProfile) return <div>User not found</div>;

  return (
    <StyledContainer>
      <>
        <PageHeader
          breadcrumbItems={breadcrumbItems}
          title={getUserName(userProfile)}
          href={""}
        />
        <StyledSectionContainer>
          <StyledTitle variant='title2'>
            {tUsers("users.list.subtitle.info")}
          </StyledTitle>
          <StyledCardContainer>
            <StyledCard>
              <StyledCardTitle variant='title2'>
                {tUsers("users.cards.user")}
              </StyledCardTitle>
              <Divider />
              <StyledIdentity
                avatar={{
                  firstName: userProfile.firstName,
                  lastName: userProfile.lastName,
                  type: "initials",
                }}
                subtitle={userProfile.email}
                title={getUserName(userProfile)}
              />
            </StyledCard>
            <StyledCard>
              <StyledCardTitle variant='title2'>
                {" "}
                {tUsers("users.cards.phoneNumber")}
              </StyledCardTitle>
              <Divider />
              <StyledCardContent variant='title2'>
                {userProfile.phone}
              </StyledCardContent>
            </StyledCard>
            <StyledRoundedTopRightCard>
              <StyledCardTitle variant='title2'>
                {tUsers("users.cards.jobTitle")}
              </StyledCardTitle>
              <Divider />
              <StyledCardContent variant='title2'>
                {userProfile.jobTitle}
              </StyledCardContent>
            </StyledRoundedTopRightCard>
          </StyledCardContainer>
        </StyledSectionContainer>
        <AccountsList userId={id} />
      </>
    </StyledContainer>
  );
};
