import { capitalize } from "@mui/material";
import {
  BubbleText,
  ContextualMenu,
  Subtract,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { usePaginatedGetSearchAgents } from "@/api/graphql/agents/search";
import {
  StyledButton,
  StyledButtonContainer,
  StyledCell,
  StyledCellContextualMenu,
  StyledCellHeaderText,
  StyledCellText,
  StyledCurrentCountOnTotal,
  StyledCustomTableContainer,
  StyledRowContainerLink,
  StyledTable,
  StyledTableHeader,
} from "@/components/Agents/List";
import { getFilters } from "@/components/Agents/List/mappers.ts";
import { AgentsListProps } from "@/components/Agents/List/types.ts";
import { getStatus, getStatusLabel } from "@/components/BackOffice/helper.ts";
import { Loading } from "@/components/shared/Loading";
import { RolesProfileIcon } from "@/components/shared/RolesProfileIcon";
import EditUser from "@/components/UserForm/Edit/component.tsx";
import { useAddSnackbar } from "@/contexts/snackbar";
import { useFormatDate } from "@/hooks/i18n/useFormatDate";
import { FeSupportUser } from "@/types/agents.ts";

export const AgentsListComponent = (props: Partial<AgentsListProps>) => {
  const {
    data: listOfAgents,
    isLoading,
    totalOfDisplayableSupportUsersWithCurrentParameters,
    fetchNextPage,
  } = usePaginatedGetSearchAgents({
    filters: getFilters(props),
  });
  const { t: tUsers } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(-1);
  const addSnackbar = useAddSnackbar();
  const [selectedAgent, setSelectedAgent] = useState<FeSupportUser | null>(
    null,
  );
  const formatDate = useFormatDate();
  const formattedDate = (date: any) => {
    const millisecondsToDateObject = new Date(Number(date) * 1000);

    return formatDate(millisecondsToDateObject, "short");
  };
  const getLinks = (agent: FeSupportUser) => {
    switch (agent.status) {
      case "SUPPORT_USER_ENABLED":
        return [
          {
            text: tUsers("users.actions.edit"),
            size: "s",
            textAlign: "left",
            className: "customMenuItem",
            onClick: () => {
              setSelectedAgent(() => agent);
            },
          },
          {
            text: tUsers("users.actions.deactivate"),
            size: "s",
            color: "red",
            textAlign: "left",
            onClick: () => {
              addSnackbar({
                type: "success",
                title: tUsers("users.actions.deactivated"),
                message: "",
                isAutoClose: true,
              });
            },
          },
        ];
      case "SUPPORT_USER_DISABLED":
        return [
          {
            text: tUsers("users.actions.reactivate"),
            size: "s",
            textAlign: "left",
            onClick: () => {
              addSnackbar({
                type: "success",
                title: tUsers("users.actions.reactivated"),
                message: "",
                isAutoClose: true,
              });
            },
          },
        ];
      case "SUPPORT_USER_INVITING":
        return [
          {
            text: tUsers("users.actions.reinvite"),
            size: "s",
            textAlign: "left",
            className: "customMenuItem",
            onClick: () => {
              addSnackbar({
                type: "success",
                title: tUsers("users.actions.reinvited"),
                message: tUsers("users.actions.reinvitedDescription", {
                  email: agent.email,
                }),
                isAutoClose: true,
              });
            },
          },
          {
            text: tUsers("users.actions.deactivate"),
            size: "s",
            textAlign: "left",
            color: "red",
            onClick: () => {
              addSnackbar({
                type: "success",
                title: tUsers("users.actions.cancelInvitation"),
                message: tUsers("users.actions.cancelInvitationDescription", {
                  email: agent.email,
                }),
                isAutoClose: true,
              });
            },
          },
        ];
      default:
        return [];
    }
  };

  return (
    <>
      {selectedAgent && (
        <EditUser
          isOpen={Boolean(selectedAgent)}
          onClose={() => setSelectedAgent(() => null)}
          userData={{
            ...selectedAgent,
            name: selectedAgent?.firstName || "",
            familyName: selectedAgent?.lastName || "",
            profile:
              selectedAgent?.accountProfile === "SUPER_AGENT"
                ? "SUPER_AGENT"
                : "AGENT",
            email: selectedAgent?.email || "",
            iGG: selectedAgent?.igg || "",
            function: "",
          }}
        />
      )}
      <Loading isLoading={isLoading || false}>
        <>
          <StyledCustomTableContainer>
            <StyledTableHeader>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tUsers("companies.list.data.email")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tUsers("companies.list.data.name")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tUsers("IGG")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tUsers("companies.list.data.accountProfile")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tUsers("companies.list.data.status")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tUsers("companies.list.data.createdAt")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tUsers("companies.list.data.lastConnection")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {""}
                </StyledCellHeaderText>
              </StyledCell>
            </StyledTableHeader>

            <StyledTable>
              {(listOfAgents || []).map((profile, index) => (
                <StyledRowContainerLink key={profile.id}>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {profile.email}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {profile.firstName} {profile.lastName}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {profile.igg}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <RolesProfileIcon
                      code={
                        (profile.accountProfile as "ADMIN" | "MANAGER") ?? ""
                      }
                      size={28}
                    />
                    <StyledCellText variant='text2'>
                      {profile.accountProfile}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      <BubbleText
                        text={capitalize(
                          tUsers(
                            `users.list.status.${getStatusLabel(profile.status)}.label`.toLowerCase(),
                          ),
                        )}
                        variant={getStatus(profile.status)}
                      />
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {formattedDate(profile.createdAt)}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {formattedDate(profile.updatedAt)}
                    </StyledCellText>
                  </StyledCell>

                  <StyledCellContextualMenu>
                    <Text variant='text2'>
                      <ContextualMenu
                        dataTestId='contextual-menu'
                        open={index === menuOpen}
                        handleClose={() => {
                          setMenuOpen(-1);
                        }}
                        handleOpen={() => {
                          setMenuOpen(index);
                        }}
                        links={getLinks(profile)}
                      />
                    </Text>
                  </StyledCellContextualMenu>
                </StyledRowContainerLink>
              ))}
            </StyledTable>
          </StyledCustomTableContainer>
          {totalOfDisplayableSupportUsersWithCurrentParameters >
          (listOfAgents?.length || 0) ? (
            <StyledButtonContainer>
              <StyledButton
                leftIcon={<Subtract />}
                variant='secondary'
                buttonText={"Load more"}
                isLowercase={true}
                onClick={() =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  fetchNextPage()
                }
              ></StyledButton>
            </StyledButtonContainer>
          ) : null}
        </>
      </Loading>
      <StyledCurrentCountOnTotal
        currentCount={listOfAgents?.length || 0}
        total={totalOfDisplayableSupportUsersWithCurrentParameters}
        postFix={"support users"}
      />
    </>
  );
};
