import { TextField } from "@totalenergiescode/mobility-business-rev-design-system";
import { Controller, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { type ControlledTextFieldProps } from "./types";

export const ControlledTextField = <T extends FieldValues>({
  name,
  control,
  namespace,
  textFieldProps,
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const isError =
          typeof textFieldProps?.isError !== "undefined"
            ? textFieldProps.isError
            : !!fieldState.error;
        const errorText = textFieldProps?.errorText
          ? textFieldProps.errorText
          : fieldState.error?.message && namespace
            ? t(fieldState.error.message, { ns: namespace })
            : undefined;

        return (
          <TextField
            {...(textFieldProps || {})}
            value={field.value}
            onChange={(event) => {
              field.onChange(event);
              textFieldProps?.onChange?.(event);
            }}
            isError={isError}
            errorText={errorText}
          />
        );
      }}
    />
  );
};
