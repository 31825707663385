import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

import AddUserForm from "@/components/UserForm/Add/component";
import { checkAuthorizationByRoles } from "@/helpers/authorization";
import { useMe } from "@/hooks/session/useMe";

const AddUser = () => {
  const { data: meData, isLoading } = useMe();
  const router = useNavigate();

  useEffect(() => {
    if (
      !isLoading &&
      !meData?.AccountsMsMe.supportUserInfo.rights?.includes("SUPER_AGENT")
    ) {
      router({ to: "/" });
    }
  }, [meData, isLoading, router]);

  return (
    <div>
      <AddUserForm />
    </div>
  );
};

export const Route = createFileRoute("/backoffice-users/add/")({
  beforeLoad: async ({ context }) => {
    checkAuthorizationByRoles(context.roles);
  },
  component: AddUser,
});
